<!--
    内容：员工业绩
    作者：ada-xu
-->
<template>
    <div class='page scroll'>
        <filter-section ref="filterSection" @search="handleSearh" optFlag="boss"></filter-section>

        <!-- 总业绩 -->
        <div class="top-cell" v-if="rankList.length">
            <div class="radius">
                <div class="ranking">
                    <!-- 第二名 -->
                    <div class="rankitem rankitem_two">
                        <div v-if="rankList.length>1">
                          <div class="avator-box">
                              <img src="@/assets/img/avator.png" class="user_avator">
                              <img src="@/assets/img/wdzl_icon_yajun@2x.png" class="wdzl_icon"/>
                          </div>
                          <p class="name">{{rankList[1].staffName}}</p>
                          <p class="text-danger font-20">{{rankList[1].resultsAmount | moneyFilter}}</p>
                        </div>                  
                    </div>
                    <!-- 第一名 -->
                    <div class="rankitem rankitem_one">
                        <div class="avator-box">
                            <img src="@/assets/img/avator.png" class="user_avator">
                            <img src="@/assets/img/wdzl_icon_guanj@2x.png" class="wdzl_icon"/>
                        </div>
                        <p class="name">{{rankList[0].staffName}}</p>
                        <p class="text-danger font-20">{{rankList[0].resultsAmount | moneyFilter}}</p>
                    </div>
                    <!-- 第三名 -->
                    <div class="rankitem  rankitem_three">
                        <div v-if="rankList.length>2">
                            <div class="avator-box">
                                <img src="@/assets/img/avator.png" class="user_avator">
                                <img src="@/assets/img/wdzl_icon_jijun@2x.png" class="wdzl_icon"/>
                            </div>
                            <p class="name">{{rankList[2].staffName}}</p>
                            <p class="text-danger font-20">{{rankList[2].resultsAmount | moneyFilter}}</p>
                        </div>                  
                    </div>
                    
                </div>
            </div>
        </div>

        <!-- 项目列表 -->
        <div class="cell-table">
            <div class="d-flex a-center bg-white text-muted">
                <div class="cell-td">员工</div>
                <div class="cell-td">总业绩</div>
                <div class="cell-td">总提成</div>
                <div class="cell-td">总客数</div>
            </div>
            <div class="d-flex a-center tr-table" style="color: #333;font-weight:bold;" v-for="(item,index) in dataList" :key="index">
                <div class="cell-td">
                  <span>{{item.staffName}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.resultsAmount}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.rewardAmount}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.resultsCnt}}</span>
                </div>
            </div>
            <van-empty v-if="dataList.length===0"/>
        </div>
        
    </div>
</template>
<script>
import ajax from '@/api/index';
import filterSection from '@/components/filterSeciton/index';
export default {
    name: 'staffPerformance' ,
    components:{
        filterSection,
    },
    data(){
        return{
          form : {
              //开始时间
              startDate: "",
              //结束时间
              endDate: "",
          },     
          //项目列表
          dataList : [],
          showPopup : false,
          currentStaff : {},
        }
    },
    computed : {
        rankList(){
          return this.dataList.filter((item,index)=>{
            return index<3
          })
        } 
    },
    methods : {
        /**
         * 查询数据
         */
        handleSearh(param){
            this.form = Object.assign(this.form,param);
            this.queryList();
        },
        /**
         * 获取员工业绩列表
         */
        queryList(){
            ajax.post('storeStaffResults',{
                qryStartDate : this.form.startDate,
                qryEndDate : this.form.endDate,
                storeId : this.form.storeId,
            }).then(res=>{
                if (res.responeCode==='0') {
                    this.dataList = res.data || [];
                } else {
                    this.$toast(res.responeMsg || '请求失败')
                }
            });     
        },
    },
}
</script>
<style scoped lang='scss'>
.page{
  .top-cell{
    margin-top: 40px;
    .radius{
      border-radius: 12px;
      background-color: #fff;
      padding: 40px 20px;
      b{
        font-weight: bold;
      }
    }
    .ranking{
      text-align: center;
      font-size: 28px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .rankitem{
          width: 33.33%;
          img{
            width: 60%;
          }
          .avator-box {
             position: relative;
             margin: 0 auto 20px; 
             border-radius: 50%;
          }
          .user_avator{
             display: block;
             width: 100%;
             height: 100%;
             border-radius: 50%;
          }
          .wdzl_icon{
            position: absolute;
            bottom: 0;
            right: 0;
          }
          p{
            font-weight: bold;
          }
          .name {
              font-size: 28px;
              color: #333;
              margin-bottom: 6px;
          }
      }
      .rankitem_one {
          .avator-box{
            width: 150px;
            height: 150px;
            border: 4px solid #FFD821;
          }
          .wdzl_icon{
            width: 48px;
            height: 40px;
          }
      }
      .rankitem_two {
          .avator-box{
            width: 120px;
            height: 120px;
            border: 4px solid #D5E2F0;
          }
          .wdzl_icon{
            width: 38px;
            height: 32px;
          }
      }
      .rankitem_three {
          .avator-box{
            width: 120px;
            height: 120px;
            border: 4px solid #EACE97;
          }
          .wdzl_icon{
            width: 38px;
            height: 32px;
          }
      }
      span{
        display: block;
      }
    }
  }
  .cell-table{
      margin-top: 20px;
      padding: 20px;
      background-color: #fff;
      border-radius: 12px;
      .cell-td{
          padding: 20px 10px;
          width: 25%;
          text-align: center;
      } 
      .tr-table{
          border-bottom: 1px solid #F5F5F5;
          color: #333;
          font-weight:bold;
          &:last-child{
            border-bottom: none;
          }
      }
  } 
}
.van-popup-content{
  padding: 20px;
  p{
    padding: 5px 0;
  }
}
</style>