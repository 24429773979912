<!--
    内容：门店业绩
    作者：ada-xu
-->
<template>
  <div class="page scroll">
      <filter-section ref="filterSection" @search="handleSearh"  optFlag="boss"></filter-section>
      <div class="top-cell">

        <!-- 营业汇总 --> 
        <div class="radius">
            <div class="total">
              <span class="font-44"><b class="text-danger">{{totalAmount | moneyFilter}}</b></span>
              <span class="text-muted">营业汇总</span>
            </div>
            <ul class="performance-li">
                <li>
                    <span class="font-28"><b class="text-danger">{{cashTotalAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">总现金</span>
                </li>
                <li>
                    <span class="font-28"><b class="text-danger">{{cardTotalAmount | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">总销卡</span>
                </li>
            </ul>
        </div>

        <!-- 现金收入汇总 -->
        <div class="radius mt-24">
            <div class="total">
              <span class="font-44"><b class="text-danger">{{cashListTotal | moneyFilter}}</b></span>
              <span class="text-muted">现金收入合计</span>
            </div>
            <ul class="performance-li">
                <li v-for="(item,index) in cashList">
                    <span class="font-28"><b class="text-danger">{{item.price | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">{{item.channelName}}</span>
                </li>
            </ul>
        </div>

        <!-- 卡金消耗合计 -->
        <div class="radius mt-24">
            <div class="total">
                <span class="font-44"><b class="text-danger">{{cardListTotal | moneyFilter}}</b></span>
                <span class="text-muted">卡金消耗合计</span>
            </div>
            <ul class="performance-li">
                <li v-for="(item,index) in cardList">
                    <span class="font-28"><b class="text-danger">{{item.price | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">{{item.channelName}}</span>
                </li>
            </ul>
        </div>

        <!-- 其他业绩合计 -->
        <div class="radius mt-24">
            <div class="total">
                <span class="font-44"><b class="text-danger">{{otherListTotal | moneyFilter}}</b></span>
                <span class="text-muted">其他业绩合计</span>
            </div>
            <ul class="performance-li">
                <li v-for="(item,index) in otherList">
                    <span class="font-28"><b class="text-danger">{{item.price  | moneyFilter}}</b></span>
                    <span class="text-muted mt-10">{{item.channelName}}</span>
                </li>
            </ul>
        </div>

      </div>
  </div>
</template>
<script>
import ajax from '@/api/index';
import filterSection from '@/components/filterSeciton/index';
export default {
    name: 'storePerformance' ,
    components: {
        filterSection,
    },
    data() {
      return {
        form : {
            //开始时间
            startDate: "",
            //结束时间
            endDate: "",
            //门店编码
            storeId : "",
        },
        //总销卡金额
        cardTotalAmount : null,
        //总现金金额
        cashTotalAmount : null,
        //卡金消耗列表
        cardList : [],
        //现金收入列表
        cashList : [],
        //其他业绩列表
        otherList : [],
      }
    },
    computed : {
        //营业汇总金额
        totalAmount(){
            return Number(this.cardTotalAmount) + Number(this.cashTotalAmount);
        },
        //卡金消耗合计
        cardListTotal(){
            let total = 0
            this.cardList.map(item=>{
              total += Number(item.price || 0);
            })
            return total.toFixed(2);
        },
        //现金收入合计
        cashListTotal(){
            let total = 0
            this.cashList.map(item=>{
                total += Number(item.price || 0);
            })
            return total.toFixed(2);
        },
        //其他业绩合计
        otherListTotal(){
            let total = 0
            this.otherList.map(item=>{
                total += Number(item.price || 0);
            })
            return total.toFixed(2);
        }, 
    },
    methods : {
        /**
         * 查询数据
         */
        handleSearh(param){
            this.form = Object.assign(this.form,param);
            this.queryStoreSummary();
        },
        /**
         * 获取店内业绩和提成
         */
        queryStoreSummary() {
            ajax.post('storeSummary',{
                qryStartDate : this.form.startDate,
                qryEndDate : this.form.endDate,
                storeId : this.form.storeId,
            }).then(res=>{
                if (res.responeCode==='0' && res.data) {
                    this.cardTotalAmount = res.data.cardTotalAmount;
                    this.cashTotalAmount = res.data.cashTotalAmount;
                    this.cardList = res.data.cardList || [];
                    this.cashList = res.data.cashList || [];
                    this.otherList = res.data.otherList || [];
                    if(this.cardList.length%2){
                      this.cardList.push({});
                    }
                    if(this.cashList.length%2){
                      this.cashList.push({});
                    }
                    if(this.otherList.length%2){
                      this.otherList.push({});
                    }
                } else {
                    this.cardTotalAmount = 0;
                    this.cashTotalAmount = 0;
                    this.cardList = [];
                    this.cashList = [];
                    this.otherList = [];
                    this.$toast(res.responeMsg || '接口请求失败')
                }
            })
        },
    },
}
</script>
<style scoped lang='scss'>
.page{
  padding-bottom: 24px;
  .top-cell{
    margin-top: 40px;
    .radius{
      border-radius: 12px;
      background-color: #fff;
      padding: 40px 20px;
      b{
        font-weight: bold;
      }
    }
    .total{
      text-align: center;
      font-size: 28px;
      span{
        display: block;
      }      
    }
    .performance-li{
      padding-top: 40px;
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-wrap: wrap;
      li{
        width: 50%;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 25px;
        border-top: 1px solid #F5F5F5;
        box-sizing: border-box;
        span{
          display: block;
        }
        &:nth-of-type(2n-1){
          border-right: 1px solid #F5F5F5;
        }
        &:nth-of-type(1),&:nth-of-type(2){
          border-top: none;
        }
      }
    }
    .mt-24{
      margin-top: 24px;
    }
  }
}
</style>