<!--
    内容：项目业绩
    作者：ada-xu
-->
<template>
    <div class='page scroll'>
        <filter-section ref="filterSection" @search="handleSearh" optFlag="boss"></filter-section>
        
        <!-- 总业绩 -->
        <div class="top-cell">
            <div class="radius">
                <div class="total">
                    <span class="mt-10 font-44">
                      <b class="text-danger">{{resultsCnt.resultsAmount | moneyFilter}}</b>
                      (<i>{{resultsCnt.guestNumber | contentFilter}}</i>)
                    </span>
                    <span class="text-muted">总业绩（客数）</span>
                  </div>
                <ul class="performance-li">
                    <li>
                        <span class="font-28">
                          <b class="text-danger">{{resultsCnt.boyAmount | moneyFilter}}</b>
                          (<i>{{resultsCnt.boyNumber | contentFilter}}</i>)
                        </span>
                        <span class="text-muted">男客业绩（客数）</span>
                    </li>
                    <li>
                        <span class="font-28">
                          <b class="text-danger">{{resultsCnt.girlAmount | moneyFilter}}</b>
                          (<i>{{resultsCnt.girlNumber | contentFilter}}</i>)
                        </span>
                        <span class="text-muted">女客业绩（客数）</span>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 项目列表 -->
        <div class="cell-table">
            <div class="d-flex a-center bg-white text-muted2">
                <div class="cell-td">服务项目</div>
                <div class="cell-td">数量</div>
                <div class="cell-td">业绩</div>
                <div class="cell-td">占比</div>
            </div>
            <div class="d-flex a-center" :class="{'odd':index%2===1}" style="color: #333;font-weight:bold;" v-for="(item,index) in itemList" :key="index">
                <div class="cell-td">
                  <span>{{item.itemName}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.numberCnt}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.amount}}</span>
                </div>
                <div class="cell-td">
                  <span>{{item.proportion}}</span>
                </div>
            </div>
            <van-empty v-if="itemList.length===0"/>
        </div>

    </div>
</template>
<script>
import ajax from '@/api/index';
import filterSection from '@/components/filterSeciton/index';
export default {
    name: 'projectPerformance' ,
    components:{
        filterSection,
    },
    data(){
        return{
            form : {
                //开始时间
                startDate: "",
                //结束时间
                endDate: "",
                //门店编码
                storeId : "",
            },     
            //项目列表
            itemList : [],
            //总业绩
            resultsCnt : {},
        }
    },
    methods : {
        /**
         * 查询数据
         */
        handleSearh(param){
            this.form = Object.assign(this.form,param);
            this.queryList();
        },
        /**
         * 获取项目列表
         */
        queryList(){
            ajax.post('storeProjectCnt',{
                qryStartDate : this.form.startDate,
                qryEndDate : this.form.endDate,
                storeId : this.form.storeId,
            }).then(res=>{
                if (res.responeCode==='0' && res.data) {
                    this.itemList = res.data.itemList || [];
                    this.resultsCnt = res.data.resultsCnt || {};
                } else {
                    this.itemList = [];
                    this.resultsCnt = {};
                    this.$toast(res.responeMsg || '接口请求失败')
                }
            });
        },
    },
}
</script>
<style scoped lang='scss'>
.page{  
  .top-cell{
    margin-top: 40px;
    .radius{
      border-radius: 12px;
      background-color: #fff;
      padding: 40px 20px;
      b{
        font-weight: bold;
      }
      i{
        font-style: normal;
      }
    }
    .total{
      text-align: center;
      font-size: 28px;
      span{
        display: block;
      }      
    }
    ul.performance-li{
      padding-top:50px;
      padding-bottom: 15px;
      display: flex;
      li{
        width: 50%;
        text-align: center;
        border-right: 2px solid #f0ebeb;
        span{
          display: block;
        }
        &:last-child{
          border: none;
        }
      }
    }
  }
  .cell-table{
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    .cell-td{
      padding: 20px 10px;
      width: 25%;
      text-align: center;
    }  
    .odd{
      background-color: #f7f8fc;
    }
  } 
}
</style>