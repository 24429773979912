<!--
    内容：我的
    作者：ada-xu
-->
<template>
    <div class='page scroll'>
        <!-- 用户基本信息 -->
        <div class="user-top" v-if="userInfo">
            <img class="user-avatar" :src="userInfo.headImgUrl" alt="会员头像">
            <div class="base-info">
                <h3>{{userInfo.nickname | contentFilter}}</h3>
                <p>{{userInfo.mobile | contentFilter}}</p>
                <p>{{userInfo.remarks | contentFilter}}</p>
            </div>
        </div>
        <!-- 金额信息 -->
        <div class="account-balance-info">
            <div class="item">
                <h3><span>￥{{userInfo.storedAmount}}</span></h3>
                <p>储值金额</p>
            </div>
            <div class="item">
                <h3><span>￥{{userInfo.givenAmount}}</span></h3>
                <p>赠送金额</p>
            </div>
            <div class="item" @click="toPath('myPoints')">
                <h3><span>￥{{userInfo.score}}</span></h3>
                <p>消费积分</p>
            </div>
        </div>
        <!-- 会员服务 -->
        <div class="more_serve">
            <div class="serve_header">
                <!-- <i class="serve_icon"></i> -->
                <span>会员服务</span>
            </div>
            <ul class="serve_ul">
                <!--始终开启3个注册,一个人可以多家门店-->
                <li @click="toPath('memberRegister')">
                    <img :src="require('@/assets/img/wd_icon_hyzc@2x.png')" alt="">
                    <span>会员注册</span>
                </li>
                <li @click="toPath('staffRegister')">
                    <img :src="require('@/assets/img/wd_icon_ygzc@2x.png')" alt="">
                    <span>员工注册</span>
                </li>
                <li @click="toPath('keeperRegister')">
                    <img :src="require('@/assets/img/wd_icon_dzzc@2x.png')" alt="">
                    <span>店主注册</span>
                </li>
                <li @click="toPath('memberCard')" v-if="userInfo.member">
                    <img :src="require('@/assets/img/wd_icon_wdhyk@2x.png')" alt="">
                    <span>我的会员卡</span>
                </li>
                <li @click="toPath('memberPackage')" v-if="userInfo.member">
                    <img :src="require('@/assets/img/wd_icon_tc@2x.png')" alt="">
                    <span>我的套餐</span>
                </li>
                <li @click="toPath('selectItem',true,'member')" v-if="userInfo.member">
                    <img :src="require('@/assets/img/wd_icon_wdjf@2x.png')" alt="">
                    <span>预约服务</span>
                </li>
                <li @click="toPath('serviceRecord')" v-if="userInfo.member">
                    <img :src="require('@/assets/img/wd_icon_fwjl@2x.png')" alt="">
                    <span>服务记录</span>
                </li>
                <li @click="toPath('bookRecord')" v-if="userInfo.member">
                    <img :src="require('@/assets/img/wd_icon_yyfw@2x.png')" alt="">
                    <span>预约记录</span>
                </li>
            </ul>
        </div>
        <!-- 员工服务 -->
        <div class="more_serve" v-if="userInfo.storeStaff">
            <div class="serve_header">
                <!-- <i class="serve_icon"></i> -->
                <span>员工服务</span>
            </div>
            <ul class="serve_ul">
                <li @click="toPath('myOverview')">
                    <img :src="require('@/assets/img/wd_icon_wdzl@2x.png')" alt="">
                    <span>我的总览</span>
                </li>
                <li @click="toPath('myPerformance')">
                    <img :src="require('@/assets/img/wd_icon_wdyj@2x.png')" alt="">
                    <span>我的业绩</span>
                </li>
                <li @click="toPath('myCommission')">
                    <img :src="require('@/assets/img/wd_icon_wdtc@2x.png')" alt="">
                    <span>我的提成</span>
                </li>
                <li @click="toPath('myAttendance')">
                    <img :src="require('@/assets/img/wd_icon_wdkq@2x.png')" alt="">
                    <span>我的考勤</span>
                </li>
                <li @click="clockIn()">
                    <img :src="require('@/assets/img/scan.png')" alt="">
                    <span>扫码打卡</span>
                </li>
            </ul>
        </div>
        <!-- 店主服务 -->
        <div class="more_serve" v-if="userInfo.storeKeeper">
            <div class="serve_header">
                <!-- <i class="serve_icon"></i> -->
                <span>店主服务</span>
            </div>
            <ul class="serve_ul serve_storeKeeper">
                <li @click="toPath('storePerformance')">
                    <img :src="require('@/assets/img/wd_icon_dnyj@2x.png')" alt="">
                    <span>店内业绩</span>
                </li>
                <li @click="toPath('projectPerformance')">
                    <img :src="require('@/assets/img/wd_icon_wdyj@2x.png')" alt="">
                    <span>项目业绩</span>
                </li>
                <li @click="toPath('staffPerformance')">
                    <img :src="require('@/assets/img/wd_icon_wdhyk@2x.png')" alt="">
                    <span>员工业绩</span>
                </li>
                <li @click="toPath('storeDeviceList',true,'boss')">
                    <img :src="require('@/assets/img/wd_icon_mdyq@2x.png')" alt="">
                    <span>门店仪器</span>
                </li>
            </ul>
        </div>
      <!-- 选择门店模态框 -->
      <selectStoreModal ref="selectStoreModal"></selectStoreModal>
    </div>
</template>
<script>
import ajax from '@/api/index';
import { mapState } from "vuex";
import wx from 'weixin-js-sdk';
import selectStoreModal from '@/components/selectStoreModal/index';
export default {
    name : 'mine' ,
    data(){
      return {}
    },
    computed : {
        ...mapState([
            'userInfo',//用户信息
        ])
    },
    components:{
      selectStoreModal,
    },
    created(){
        //设置默认头像、备注
        if (!this.userInfo.headImgUrl) {
            this.userInfo.headImgUrl = require('./../../assets/img/avator.png');
        }
        if (!this.userInfo.remarks) {
            this.userInfo.remarks = '科技美容，专属服务，别致美丽';
        }
        if (this.userInfo.member || this.userInfo.storeStaff || this.userInfo.storeKeeper) {
            this.getSign();
        }
    },
    activated(){
        //关闭弹窗
        this.$refs.selectStoreModal.hide();
    },
    methods : {
        /**
         * 跳转
         * @param toName 跳转路由
         * @param needSelectStore 是否需要选择门店
         * @param optFlag 用户类型
         */
        toPath(toName,needSelectStore,optFlag) {
            if (needSelectStore) {
                //先获取门店再打开弹窗选择
                ajax.post('getCustomerStores',{
                    optFlag
                }).then(res => {
                    if (res.responeCode === '0') {
                        let storeList = res.data || [];
                        if (storeList.length===0) {
                            //未绑定门店
                            this.$toast('当前用户未绑定门店,请进行会员注册');
                        } else {
                            this.$refs.selectStoreModal.show({
                                storeList,
                                closeable : true,
                                selectId : null,
                                confirmCallback : (storeId)=>{
                                    this.$router.push({
                                        name : toName,
                                        query : { 
                                            storeId,
                                            from : 'mine',//来自的路由
                                        }
                                    })
                                }
                            });
                        }
                    } else {
                        // this.$toast(res.responeMsg || '门店列表获取失败');
                    }
                })
              
            } else {
              this.$router.push({
                name : toName
              })
            }
        },
        /**
         * 获取微信签名信息
         */
        getSign() {
            //解决ios第一次无法调用微信扫一扫的功能，如果直接使用location.href.split('#')[0]进行获取链接的话，在IOS系统中第一次可能会无法进行正常的签名操作。
            //原因：微信会对IOS页面链接做处理，虽然使用location.href.split('#')[0]获取输出的链接，为当前的页面，但是实际上，在IOS页面的链接，并不是这一个链接，所以会导致签名失败的情况
            let purl = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');
            let dataForm = {
                'url': purl
            }
            // this.$toast('正在加载配置文件，请稍后');
            //获取微信签名信息
            ajax.post('getJsConfig').then((res) => {
                if (res.responeCode == '0' && res.data) {
                    let configData = {
                        debug: false,
                        appId: res.data.appId, // 必填，公众号的唯一标识
                        timestamp: "" + res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名
                        jsApiList: ['scanQRCode', 'checkJsApi']
                    }
                    wx.config(configData);
                } else {
                    this.$toast("获取签名信息失败，请刷新页面重新获取");
                }
            });
        },
        /**
         * 调用微信扫一扫功能
         */
        clockIn() {
            let that = this;
            wx.ready(function () {
                wx.checkJsApi({
                    jsApiList: ['scanQRCode'],
                    success: function (res) {
                    if (res.checkResult.scanQRCode === true) {
                        wx.scanQRCode({ // 微信扫一扫接口
                        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                        scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                        success: function (res) {
                            ajax.post('userDoWork',{
                                code : res.resultStr //扫码返回的结果
                            }).then(res=>{
                                if(res.responeCode==='0'){
                                    that.$toast('打卡成功');
                                } else  {
                                    that.$toast(res.responeMsg || '打卡失败');
                                }
                            })
                        }
                        })
                    } else {
                        that.$toast("抱歉，当前客户端版本不支持扫一扫");
                    }
                    },
                    fail: function (res) { // 检测getNetworkType该功能失败时处理
                        that.$toast('fail' + res)
                    }
                });
            });
            //错误信息
            wx.error(function (res) {
                that.$toast("出错了：" + res.errMsg);
            });
        },
    },
}
</script>
<style scoped lang='scss'>
  @import '~@/assets/scss/base';
  .user-top{
      display: flex;
      position: sticky;
      align-items: center;
      .user-avatar{
          width: 120px;
          height: 120px;
          border-radius: 50%;
          flex-shrink: 0;
      }
      .base-info{
          flex: 1;
          margin-left: 20px;
          h3{
              font-weight: bold;
              color: $color_333;
              font-size: $font_size_34px;
          }
          p{
              color: $color_666;
              line-height: 38px;
          }
      }
  }
  .account-balance-info{
      display: flex;
      padding: 40px 0 ;
      background-color: #fff;
      margin-top: 46px;
      border-radius: 12px;
      .item{
          flex: 1;
          text-align: center;
          border-right: 2px solid #f0ebeb;
          h3{
              color: $color_red;
              line-height: 20px;
              font-weight: bold;
              sub{
                font-size: 14px;
              }
              span{
                font-size: 26px;
              }
          }
          p{
              color:$color_666;
              margin-top: 10px;
          }
          &:last-child{
              border-right: none;
          }
      }
  }
  .more_serve{
    border-radius: 12px;
    background-color: #fff;
    margin-top: 24px;
    .serve_header{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        .serve_icon{
            display: inline-block;
            margin-right: 10px;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        span{
            font-size: 28px;
            color: #1d1d1d;
            font-weight: bold;
            color:$color_333;
        }
    }
    .serve_ul{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 20px;
        li{
            width: 33.33%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            img{
                width: 80px;
                height: 50px;
                padding: 10px 0;
                object-fit: scale-down;
            }
            span{
                padding: 10px 0;
                color:$color_333;
                font-size: 24px;
            }
        }
    }
    .serve_storeKeeper {
        li {
            width: 25%;
        }
    }
}
</style>