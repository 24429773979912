<!--
    内容：解锁仪器
    作者：ada-xu
-->
<template>
  <div  class='page scroll'>
      <div class="device-list">
          <div class="item">
              <img :src="detail.cover" class="device-img">
              <div class="device-info">
                  <h4>{{detail.name}} {{detail.typeNo}}</h4>
                  <p>仪器编号：{{detail.no}}</p>
                  <p>仪器状态：<span :class="{'green-label' : detail.state==='1'}">{{detail.stateName}}</span></p>
              </div>
          </div>
          <!-- 输入水单号 -->
          <div class="form">
              <p class="title">请您输入系统服务单号(水单号):</p>
              <van-field ref="orderNoField" v-model="orderNo"  placeholder="请输入"/>
          </div>
          <p class="tips">注意：离线中的仪器不可操作，请开机1分钟后刷新状态</p>
          
      </div>
      <div class="bottom-button">
          <van-button class="btn-border" type="default" @click="refreshState()">刷新状态</van-button>
          <van-button class="btn-bg" type="default" @click="unlockMachine()">解锁仪器</van-button>
      </div>
  </div>
</template>
<script>
import ajax from '@/api/index';
export default {
    name: 'storeDeviceDetail' ,
    data(){
      return {
        //仪器信息
        detail : {},
        //水单号
        orderNo : "",
        //仪器编码
        machineNo : ""
      }
    },
    created(){
        this.machineNo = this.$route.query.id || '';
        this.getMachineInfo();
    },
    methods : {
        /**
         * 获取仪器信息
         */
        getMachineInfo(){
            ajax.post('getMachineInfo',{
                no : this.machineNo, //仪器编码
            }).then(res=>{
                if (res.responeCode==='0') {
                    this.detail = res.data || {}
                } else {
                    this.$toast(res.responeMsg || '查询失败');
                }
            })
        },
        /**
         * 刷新仪器状态
         */
        refreshState(){
            ajax.post('refreshMachineState',{
                no : this.detail.no, //仪器编码
            }).then(res=>{
                if (res.responeCode==='0' && res.data && res.data.stateName) {
                    //更新仪器状态
                    this.detail.state = res.data.state;
                    this.detail.stateName = res.data.stateName;
                    //更新缓存
                    sessionStorage.setItem('storeDeviceDetail',JSON.stringify(this.detail))
                } else {
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
        /**
         * 解锁仪器
         */
        unlockMachine(){
            if (!this.orderNo) {
                this.$toast('请输入水单号');
                this.$refs.orderNoField.focus();
                return;
            }
            ajax.post('unlockMachine',{
              no : this.detail.no, //仪器编码
              storeId : this.detail.storeId, //门店id
              orderNo : this.orderNo,//水单号
            }).then(res=>{
                if (res.responeCode==='0' && res.data && res.data.stateName) {
                    //更新仪器状态
                    this.detail.state = res.data.state;
                    this.detail.stateName = res.data.stateName;
                    //更新缓存
                    sessionStorage.setItem('storeDeviceDetail',JSON.stringify(this.detail))
                } else {
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
    }
}
</script>
<style scoped lang='scss'>
.page{
    padding-bottom: 210px;
    .device-list{
        .item{
            background-color: #fff;
            border-radius: 12px;
            padding: 30px 20px;
            display: flex;
            align-items: flex-start;
          
            .device-img{
                width: 150px;
                height: 150px;
                border-radius: 12px;
                margin-right: 20px;
            }
            .device-info{
              flex: 1;
            }
            h4{
                font-size: 34px;
                color: #333;
                font-weight: bold;
            }
            p{
                font-size: 28px;
                color: #333;
                margin-top: 10px;
            }
            .gray-label{
                color: #999;
            }
            .green-label{
                color: #54C915;
            }
            .blue-label{
                color: #0289FF;
            }
            .red-label{
                color: #FF0202;
            }
            .remove-btn{
              width: 96px;
              height: 46px;
              background-color: #FF0202;
              color: #fff;
              border-radius: 12px;
              font-size: 24px;
              padding: 0;
            }
        }
    }
    .bottom-button{
      width: 100%;
      box-sizing: border-box;
      padding: 60px 30px;
      display: flex;
      justify-content: space-between;
      background-color: #F7F9FC;
      .van-button{
          width: 300px;
          height: 94px;
          border: none;
          border-radius: 12px;
          font-weight: bold;
      }
      .btn-border{
          border:1px solid #FF0202;
          color : #FF0202
      }
      .btn-bg {
          background-color: #FF0202;
          color: #fff;
      }
  }
  .form {
      background-color: #fff;
      padding: 0 20px 60px;
      .title {
          border-top: 1px solid #F5F5F5;
          font-size: 28px;
          color: #333;
          padding:26px 0;
      }      
      .van-cell{
          padding: 0 20px;
          border-radius: 12px;
          border: 1px solid #F5F5F5;
          height: 74px;
          line-height: 74px;
          box-sizing: border-box;
      }
  }
  .tips {
      color: #999;
      text-align: center;
      padding:30px 0;
  }
}
  
</style>