<!--
    内容：门店仪器
    作者：ada-xu
-->
<template>
    <div class='page scroll'>
        <div id="list">
            <div class="device-list">
                <div class="item" v-for="(item,index) in tbData" :key="index">
                    <img :src="item.cover" class="device-img">
                    <div class="device-info">
                        <h4>
                            <span>{{item.name}} {{item.typeNo}}</span>
                            <van-button class="remove-btn" type="default" @click="handleRemove(item)">移除</van-button>
                        </h4>
                        <p>仪器编号：{{item.no}}</p>
                        <p>绑定时间：{{item.createDate}}</p>
                    </div>
                    
                </div>
            </div>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>
        <div class="bottom-button">
            <van-button class="btn-border" type="default" @click="scan('unlockMachine')">解锁仪器</van-button>
            <van-button class="btn-bg" type="default" @click="scan('bindMachine')">绑定仪器</van-button>
        </div>
    </div>
</template>
<script>
import ajax from '@/api/index';
import wx from 'weixin-js-sdk';
export default {
    name: 'storeDeviceList' ,
    data() {
        return {
            tbData : [],
            //门店iD
            storeId : '',
        };
    },
    created(){
        this.storeId = this.$route.query.storeId;
        this.getSign();
    },
    activated(){
        if (this.tbData.length===0) {
            this.queryDeviceList();
        }
    },
    methods : {
        /**
         * 获取仪器列表
         */
        queryDeviceList(){
            ajax.post('getMachineInfos',{
              storeId : this.storeId,
            }).then(res=>{
                if (res.responeCode==='0') {
                    this.tbData = res.data || [];
                } else {
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
        /**
         * 移除仪器
         * @param row 仪器信息
         */
        handleRemove(row){
           ajax.post('delMachine',{
                no : row.no, //仪器编码
                storeId : this.storeId, //门店id
            }).then(res=>{
                if (res.responeCode==='0') {
                    this.$toast('移除成功');
                    this.queryDeviceList();
                } else {
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
        /**
         * 绑定仪器
         */
        bindMachine(data){
            ajax.post('bindMachine',{
                scanStr : data,
                storeId : this.storeId,
            }).then(res=>{
                if (res.responeCode==='0') {
                    this.$toast('绑定成功');
                    this.queryDeviceList();
                } else {
                    this.$toast(res.responeMsg || '绑定失败');
                }
            })
        },
        /**
         * 解锁仪器
         */
        unlockMachine(data){
            this.$router.push({
                name : 'storeDeviceDetail',
                query : { 
                    id : data,
                }
            })
        },
        /**
         * 调用微信扫一扫功能
         */
        scan(type) {
            let that = this;
            wx.ready(function () {
                wx.checkJsApi({
                    jsApiList: ['scanQRCode'],
                    success: function (res) {
                        if (res.checkResult.scanQRCode === true) {
                            wx.scanQRCode({ // 微信扫一扫接口
                                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                                scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                                success: function (res) {
                                    if (type==='bindMachine') {
                                        that.bindMachine(res);
                                    } else if(type==='unlockMachine'){
                                        that.unlockMachine(res);
                                    } else {
                                        console.log(res);
                                    }
                                }
                            })
                        } else {
                            that.$toast("抱歉，当前客户端版本不支持扫一扫");
                        }
                    },
                    fail: function (res) { // 检测getNetworkType该功能失败时处理
                        that.$toast('fail' + res)
                    }
                });
            });
            //错误信息
            wx.error(function (res) {
                that.$toast("出错了：" + res.errMsg);
            });
        },
        /**
         * 获取微信签名信息
         */
        getSign() {
            //解决ios第一次无法调用微信扫一扫的功能，如果直接使用location.href.split('#')[0]进行获取链接的话，在IOS系统中第一次可能会无法进行正常的签名操作。
            //原因：微信会对IOS页面链接做处理，虽然使用location.href.split('#')[0]获取输出的链接，为当前的页面，但是实际上，在IOS页面的链接，并不是这一个链接，所以会导致签名失败的情况
            let purl = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');
            let dataForm = {
                'url': purl
            }
            // this.$toast('正在加载配置文件，请稍后');
            //获取微信签名信息
            ajax.post('getJsConfig').then((res) => {
                if (res.responeCode == '0' && res.data) {
                    let configData = {
                        debug: false,
                        appId: res.data.appId, // 必填，公众号的唯一标识
                        timestamp: "" + res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名
                        jsApiList: ['scanQRCode', 'checkJsApi']
                    }
                    wx.config(configData);
                } else {
                    this.$toast("获取签名信息失败，请刷新页面重新获取");
                }
            });
        },
    }
}
</script>
<style scoped lang='scss'>
.page{
  padding-bottom: 210px;
}
.device-list{
    .item{
        background-color: #fff;
        border-radius: 12px;
        padding: 30px 20px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;
        
        .device-img{
            width: 150px;
            height: 150px;
            border-radius: 12px;
            margin-right: 20px;
        }
        .device-info{
            flex: 1;
        }
        h4{
            font-size: 34px;
            color: #333;
            font-weight: bold;
        }
        p{
            font-size: 28px;
            color: #333;
            margin-top: 10px;
        }
        .remove-btn{
            width: 96px;
            height: 46px;
            background-color: #FF0202;
            color: #fff;
            border-radius: 12px;
            font-size: 24px;
            padding: 0;
            float: right;
        }
    }
}
.bottom-button{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    box-sizing: border-box;
    padding: 60px;
    display: flex;
    justify-content: space-between;
    background-color: #F7F9FC;
    .van-button{
        width: 300px;
        height: 94px;
        border: none;
        border-radius: 12px;
        font-weight: bold;
    }
    .btn-border{
        border:1px solid #FF0202;
        color : #FF0202
    }
    .btn-bg {
        background-color: #FF0202;
        color: #fff;
    }
}
</style>